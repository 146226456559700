<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <TabView v-model:activeIndex="activeTab">
                    <TabPanel :header="$t('sportsbets.addOneMarket')">
                        <div class="p-col-12 p-md-2 p-mb-2">
                            <span class="p-float-label">
                                <Dropdown id="marketsDropdown" style="width: 100%" v-model="specialMarketModel" :options="specialMarkets" optionLabel="displayName"  :filter="!this.isMobile()"  />
                                <label for="marketsDropdown">{{ $t('dropdown.placeholder.market') }}</label>
                            </span>
                        </div>
                    </TabPanel>
                    <TabPanel :header="$t('sportsbets.addTemplate')">
                        <div class="p-col-12 p-md-2 p-mb-2">
                            <span class="p-float-label">
                                <Dropdown id="marketsDropdown" style="width: 100%" v-model="templateModel" :options="templates" optionLabel="name"  :filter="!this.isMobile()"  />
                                <label for="marketsDropdown">{{ $t('dropdown.placeholder.template') }}</label>
                            </span>
                        </div>
                    </TabPanel>
                </TabView>
                <div v-if="markets.length > 0">
                    <div class="card" v-for="(market, index) in markets" :key="'vacard' + index">
                        <h6>{{ market.displayName }}</h6>
                        <div class="p-grid" v-if="market.hasSpecifier">
                            <div class="p-field p-grid p-col-12 p-md-2">
                                <label for="outcomeId" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.specifier') }}</label>
                                <div class="p-col-12 p-md-8">
                                    <InputText style="width: 100%" type="text" v-model="market.specifier" />
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3 p-text-right">
                                <Button @click="addSpecifierValue(index)" :label="$t('buttons.addValue')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                                <Button @click="removeSpecifierValue(index)" :label="$t('buttons.removeValue')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" />
                            </div>
                            <div class="p-col-12 p-md-4 p-md-offset-3 p-text-right">
                                <Button @click="removeSpecifier(index)" :label="$t('buttons.removeSpecifier')" class="p-button-text p-button-danger p-mr-2 p-mb-2" />
                            </div>
                        </div>
                        <div class="p-grid" v-else>
                            <div class="p-col-12 p-text-right">
                                <Button @click="removeSpecifier(index)" :label="$t('buttons.removeSpecifier')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" />
                            </div>
                        </div>
                        <div v-for="(outcome, index) in market.outcomes" :key="market.marketId + '_' + index + '_' + outcome.outcomeId" class="p-grid">
                            <div class="p-field p-grid p-col-12 p-md-2">
                                <label for="outcomeId" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                                <div class="p-col-12 p-md-8">
                                    <InputText style="width: 100%" type="text" v-model="outcome.outcomeId" disabled />
                                </div>
                            </div>
                            <div class="p-field p-grid p-col-12 p-md-3">
                                <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.name') }}</label>
                                <div class="p-col-12 p-md-8">
                                    <InputText style="width: 100%" type="text" v-model="outcome.name" />
                                </div>
                            </div>
                            <div class="p-field p-grid p-col-12 p-md-2">
                                <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.outcomeKey') }}</label>
                                <div class="p-col-12 p-md-8">
                                    <InputText style="width: 100%" type="text" v-model="outcome.code" />
                                </div>
                            </div>
                            <div class="p-field p-grid p-col-12 p-md-2">
                                <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }}</label>
                                <div class="p-col-12 p-md-8">
                                    <InputText style="width: 100%" type="text" v-model="outcome.sortNo" />
                                </div>
                            </div>
                            <div class="p-field p-grid p-col-12 p-md-2">
                                <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsbets.odds') }}</label>
                                <div class="p-col-12 p-md-8">
                                    <InputText style="width: 100%" type="text" v-model="outcome.odds" />
                                </div>
                            </div>
                        </div>
                        <div class="p-grid" v-if="market.hasSpecifier">
                            <div class="p-col-12 p-text-right">
                                <Button @click="addSpecifiers(index)" :disabled="disableSubmit" :label="$t('buttons.moreSpecifiers')" class="p-button-text p-button-warning p-mr-2 p-mb-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-text-right">
                    <Button @click="addMarketsToFixture()" :disabled="disableSubmit" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goBack()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { BoSportsApi } from '../../service/BoSportsApi';

export default {
    name: 'AddSpecialMarkets',
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            BoSportsApi: new BoSportsApi(),
            markets: [],
            disableSubmit: false,
            specialMarkets: [],
            specialMarketModel: {},
            templates: [],
            templateModel: {},
            activeTab: 0,
            defaultTemplates: [],
        };
    },
    props: {
        fixtureId: {
            type: Object,
        },
        sportId: {
            type: String,
        },
        backRoute: {
            type: String,
        },
        fixturesActive: {
            type: String,
            default: 'false',
        },
    },
    watch: {
        specialMarketModel() {
            this.markets = [];
            this.defaultTemplates = [this.specialMarketModel];
            this.GetMarketOutcomesAndSpecifiers(this.specialMarketModel);
        },
        templateModel() {
            this.getTemplateMarkets();
        },
        activeTab() {
            this.markets = [];
        },
    },
    mounted() {
        this.getTemplates();
        this.getAllSpecialMarkets(this.sportId);
    },
    methods: {
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        getAllSpecialMarkets(sportId) {
            this.SportsSettingsApi.getAllSpecialMarkets(sportId)
                .then((response) => {
                    this.specialMarkets = response.data;
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },

        GetMarketOutcomesAndSpecifiers(market, index = -1) {
            var marketTemplate = this.defaultTemplates.find((template) => template.marketId === market.marketId);
            if (marketTemplate != undefined) {
                var outcomes = [];
                for (var i = 0; i < market.outcomes.length; i++) {
                    var marketOutcome = {
                        outcomeId: marketTemplate.outcomes[i].outcomeId,
                        keyCode: marketTemplate.outcomes[i].keyCode,
                        sortNo: marketTemplate.outcomes[i].sortNo,
                        name: marketTemplate.outcomes[i].name,
                    };
                    outcomes.push(marketOutcome);
                }
                var marketObj = {
                    outcomes: outcomes,
                    hasSpecifier: market.hasSpecifier,
                    displayName: market.displayName,
                    marketId: market.marketId,
                    groups: market.groups,
                };
                if (index == -1) {
                    this.markets.push(marketObj);
                } else {
                    this.markets.splice(index + 1, 0, marketObj);
                }
            }
        },
        addSpecifierValue(index) {
            var market = this.markets[index];
            for (var i = 0; i < market.outcomes.length; i++) {
                market.outcomes[i].name = this.specialMarketModel.hasPlayers ? market.outcomes[i].name.replace('{player}', market.specifier) : market.outcomes[i].name.replace('{specifier}', market.specifier);
            }
        },
        removeSpecifierValue(index) {
            var market = this.markets[index];
            var marketTemplate = this.defaultTemplates.find((template) => template.marketId === market.marketId);
            if (marketTemplate != undefined) {
                for (var i = 0; i < market.outcomes.length; i++) {
                    market.outcomes[i].name = marketTemplate.outcomes[i].name;
                }
            }
        },
        addMarketsToFixture() {
            this.disableSubmit = true;
            this.showError = false;
            var newMarketsList = [];
            for (let j = 0; j < this.markets.length; j++) {
                var marketObj = {
                    Groups: this.markets[j].groups.join('|'),
                    _id: this.markets[j].marketId,
                    Name: this.markets[j].displayName,
                    Specifier: 'sp=' + this.markets[j].specifier + '|',
                    ManualOdds: true,
                    InOffer: true,
                };
                var outcomesArray = [];
                var sortedOutcomes = this.markets[j].outcomes.sort((a, b) => {
                    return a.sortNo - b.sortNo;
                });
                for (let i = 0; i < sortedOutcomes.length; i++) {
                    let outcomeObj = {
                        _id: sortedOutcomes[i].outcomeId,
                        Name: sortedOutcomes[i].name,
                        Odds: sortedOutcomes[i].odds,
                        Active: true,
                        Key: sortedOutcomes[i].keyCode,
                    };
                    outcomesArray.push(outcomeObj);
                }
                marketObj.Outcomes = outcomesArray;
                newMarketsList.push(marketObj);
            }
            if (this.fixtureId != '0') {
                this.BoSportsApi.addSpecialMarket(newMarketsList, this.fixtureId)
                    .then(() => {
                        this.goBack();
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.disableSubmit = false;
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.showToast('error', this.$t('sportsbets.alerts.invalidFixtureId'));
            }
        },
        getTemplates() {
            this.SportsSettingsApi.getSpecialMarketsGroups(this.sportId)
                .then((response) => {
                    this.templates = response.data;
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        addSpecifiers(index) {
            this.GetMarketOutcomesAndSpecifiers(this.markets[index], index);
        },
        removeSpecifier(index) {
            this.markets.splice(index, 1);
        },
        getTemplateMarkets() {
            var marketsIds = this.templateModel.specialMarketsIds.join();
            this.SportsSettingsApi.getSpecialMarketsFromGroup(marketsIds)
                .then((response) => {
                    this.markets = response.data;
                    this.defaultTemplates = JSON.parse(JSON.stringify(response.data));
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        goBack() {
            this.goto('eventDetails', { id: this.fixtureId, fixtureId: this.fixtureId, fixturesActive: this.fixturesActive, backRoute: this.backRoute });
        },
    },
};
</script>